import React from 'react';
import { useNavigate, useSearchParams, useRef } from 'react-router-dom';
import { t } from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

import { fetchSession } from '../api/fetchSession.js';
import { updateAttendanceEmail } from '../api/updateAttendanceEmail.js';
import currentUserUtils from '../utils/currentUserUtils.js';

function EmailResponse() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showRecaptcha, setShowRecaptcha] = React.useState(false);
    const recaptchaVerified = React.useRef(false);
    const [loading, setLoading] = React.useState(true);

    const participant = searchParams.get('participant');
    const sessionId = searchParams.get('session');
    const eventId = searchParams.get('event');
    const response = searchParams.get('response');

    const updateAttendance = async () => {
        const session = await fetchSession(sessionId);
        const updatedBy = currentUserUtils.getParticipantForOwner(
            session.event.owner_id
        );
        if (!updatedBy) {
            currentUserUtils.maybeSaveParticipantForOwner(
                session.event.owner_id,
                participant
            );
        }

        await updateAttendanceEmail(
            participant,
            updatedBy,
            sessionId,
            response
        );
        navigate('/session/event/' + eventId);
    };

    const validateRecaptcha = async (token) => {
        try {
            const apiUrl = process.env.REACT_APP_ROOT_URL + '/recaptcha-verify';
            const response = await axios.post(apiUrl, { token });
            const data = response.data;

            return data.success;
        } catch (error) {
            if (error.response) {
                console.error(
                    'Backend returned an error:',
                    error.response.status,
                    error.response.data
                );
            } else {
                console.error(
                    'Error during reCAPTCHA validation:',
                    error.message
                );
            }
            return false;
        }
    };

    const handleRecaptcha = async (token) => {
        if (recaptchaVerified.current) {
            console.log('Recaptcha already verified. Skipping duplicate call.');
            return;
        }

        const isValid = await validateRecaptcha(token);

        if (isValid) {
            recaptchaVerified.current = true;
            await updateAttendance();
        } else {
            alert(t('Verification failed. Please try again.'));
        }
    };

    const handleAttendance = () => {
        const now = new Date();
        const currentMinutes = now.getHours() * 60 + now.getMinutes();
        const startMinutes = 10 * 60; // 10:00
        const endMinutes = 10 * 60 + 5; // 10:05

        if (currentMinutes >= startMinutes && currentMinutes <= endMinutes) {
            setShowRecaptcha(true);
        } else {
            updateAttendance();
        }
    };

    React.useEffect(() => {
        handleAttendance();
    }, []);

    React.useEffect(() => {
        // Simulate loading for 2 seconds - no need to show any info unless users are stopped
        const timer = setTimeout(() => {
            setLoading(false);
            handleAttendance();
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="App">
            <div className="email-response">
                {response === 'yes' ? (
                    <h1>Thank you for confirming your attendance</h1>
                ) : response === 'no' ? (
                    <h1>Thank you for declining the invitation</h1>
                ) : (
                    <h1>Invalid response</h1>
                )}
                {participant && sessionId && response && (
                    <div>
                        <p>Participant: {participant}</p>
                        <p>Session: {sessionId}</p>
                        <p>Response: {response}</p>
                    </div>
                )}
                {/* Show visible reCAPTCHA only during the specified time window */}
                {showRecaptcha && (
                    <div className="recaptcha">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptcha}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default EmailResponse;
