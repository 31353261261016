//import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Select, Space, Steps, TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import 'moment/locale/is';
import React, { useEffect /*, useRef*/, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { createEvent } from '../api/createEvent.js';
import { deleteEvent } from '../api/deleteEvent.js';
import { fetchEvent } from '../api/fetchEvent.js';
import {
    fetchAllParticipantsByOwnerWithEvent,
    fetchAllowedParticipants,
} from '../api/fetchParticipants.js';
import { toggleAllowedAttendance } from '../api/participantEvents.js';
import { updateEvent } from '../api/updateEvent.js';
import Gathering from '../components/Gathering.js';
import Venue from '../components/Venue.js';
import EventSettings from '../components/dashboard/EventSettings';
import { showToast, updateToast } from '../utils/toastUtils.js';
import utils from '../utils/utils.js';

function ManageEvent() {
    const navigate = useNavigate();
    const auth = useAuth();

    const [eventData, setEvent] = useState(null);
    const [participants, setAllMyParticipants] = useState(null);

    const [is_single_event, setIsSingleEvent] = useState(true);
    const [notifications, setNotifications] = useState(true);
    const [notificationType, setNotificationType] = useState(true);
    const [event_id, setEventId] = useState();
    const [name, setEventName] = useState('');
    const [short_description, setShortDescription] = useState('');
    const [long_description, setLongDescription] = useState('');
    const [day_moment, setDayMoment] = useState(null);
    const [time_moment, setTimeMoment] = useState(null);
    const [gathering_id, setGatheringId] = useState();
    const [venue_id, setVenueId] = useState();
    const [min_participants, setMinParticipants] = useState(10);
    const [max_participants, setMaxParticipants] = useState(99);
    const [weekday, setWeekDay] = useState(0);
    const [creatingEvent, setCreatingEvent] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [needConfirm, setNeedConfirm] = useState(true);
    const [allChecked, setAllChecked] = useState(false);

    let { id } = useParams();
    const timeFormat = 'HH:mm';

    //TODO: move create and edit event to a seperate component
    //Initialize event
    const getEvent = async (eventId) => {
        try {
            const res = await fetchEvent(eventId);
            setEvent(res);
            setEventName(res.name);
            setEventId(res.event_id);
            setShortDescription(res.short_description);
            setLongDescription(res.long_description);

            setWeekDay(res.weekday);
            setGatheringId(res.gathering_id);
            setVenueId(res.venue_id);
            setMinParticipants(res.min_participants);
            setMaxParticipants(res.max_participants);
            setNotifications(res.notifications);
            setNotificationType(res.notificationType);

            if (res.single_date) {
                setDayMoment(dayjs(res.single_date));
            } else {
                let time = dayjs(res.time_of_day, 'HH:mm:ss');
                setTimeMoment(time);
            }

            setIsSingleEvent(res.type === utils.SINGLE_EVENT);
            //setActive(res.active);
        } catch (err) {
            console.error(err);
        }
    };

    const getMyParticipants = async (event_id) => {
        try {
            const res = await fetchAllParticipantsByOwnerWithEvent(
                auth.currentUser.user_id,
                event_id
            );

            setAllMyParticipants(res);
        } catch (err) {
            console.error(err);
        }
        try {
            await fetchAllowedParticipants(event_id);
        } catch (err) {
            console.error(err);
            console.log('error..', err);
        }
    };

    const validateEventData = () => {
        let checkValues = [];

        // Abort if single event is set to occur in the past.
        if (is_single_event && day_moment !== null) {
            if (day_moment < dayjs()) {
                showToast(
                    'Event dates cannot be in the past',
                    auth.currentUser.email,
                    {
                        toastId: 'toastIdErrorValidatingEvent',
                        type: 'error',
                        autoClose: 4000,
                        isLoading: false,
                    }
                );
                return;
            }
        }

        if (is_single_event) {
            if (day_moment === null) checkValues.push('event date/time');
        } else {
            if (time_moment === null) checkValues.push('schedule time');
        }
        if (name.trim() === '') checkValues.push('event name');
        if (gathering_id === undefined || gathering_id.trim() === '')
            checkValues.push('group');
        if (venue_id === undefined || venue_id.trim() === '')
            checkValues.push('venue');
        if (max_participants < min_participants) {
            checkValues.push('min & max participants');
        } else if (min_participants === 0) {
            checkValues.push('min participants');
        }

        if (checkValues.length > 0) {
            showToast(
                'Please verify: ' + checkValues.join(', ') + '.',
                auth.currentUser.email,
                {
                    toastId: 'toastIdErrorValidatingEvent',
                    type: 'error',
                    autoClose: 4000,
                    isLoading: false,
                }
            );
            return false;
        }
        return true;
    };

    const startUpdateEvent = async () => {
        if (!validateEventData()) {
            return;
        }

        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdUpdateEvent',
        });

        try {
            const res = await updateEvent(
                event_id,
                gathering_id,
                venue_id,
                name,
                short_description,
                long_description,
                is_single_event,
                weekday,
                time_moment,
                day_moment,
                min_participants,
                max_participants,
                auth.currentUser.user_id,
                notifications,
                notificationType
                // active,
            );
            setEvent(res);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Event updated',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            navigate('/admin/');

            //await getParticipants(eventId, res.Active_UUID_DATE);
            //await getMessages(eventId, res.Active_UUID_DATE);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    // Show delete Event modal.
    const handleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    // Delete event, hide modal and move to dashboard.
    const startDeleteEvent = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdDeleteEvent',
        });

        try {
            await deleteEvent(event_id);
            setShowDeleteConfirmation(false);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Event deleted',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });

            navigate('/admin/');
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const startCreateEvent = async () => {
        if (!validateEventData()) {
            return;
        }

        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdCreateEvent',
        });

        try {
            const res = await createEvent(
                name,
                gathering_id,
                short_description,
                long_description,
                is_single_event,
                weekday,
                time_moment,
                day_moment,
                venue_id,
                min_participants,
                max_participants,
                auth.currentUser.user_id,
                notifications,
                notificationType
            );
            setEvent(res);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Event created successfully - Please add participants',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });

            navigate('/admin/events/' + res.data.event_id + '?success=true');

            //await getParticipants(eventId, res.Active_UUID_DATE);
            //await getMessages(eventId, res.Active_UUID_DATE);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    useEffect(() => {
        setNeedConfirm(utils.isTestUser(auth.currentUser.email));

        if (id) {
            if (id === 'create') {
                setCreatingEvent(true);
            } else {
                getEvent(id);
                setCreatingEvent(false);
                getMyParticipants(id);
            }
        } else {
            console.log('no id');
        }
    }, [id]);

    // Handle allowed users
    const handleAllowedChange = async (eventId, participantId, allowed) => {
        const newParticipants = participants.map((participant) => {
            if (participant.participant_id === participantId) {
                return {
                    ...participant,
                    hasAllowedParticipant: allowed, // Assuming this is the correct property to toggle
                };
            }
            return participant;
        });

        try {
            // Assuming toggleAllowedAttendance properly handles adding/removing allowed attendance
            await toggleAllowedAttendance(eventId, participantId, allowed); // Ensure this function is implemented to reflect the change
            console.log(
                `Updated attendance for participant ${participantId} in event ${eventId}`
            );
            setAllMyParticipants(newParticipants);
        } catch (err) {
            console.error('Failed to update attendance:', err);
            // Handle the error appropriately
        }
    };

    const handleSingleEventChange = (e) => {
        setIsSingleEvent(e.target.value === 'true');
    };

    const handleNotificationsChange = (e) => {
        const value = e.target.value === 'true'; // Convert string to boolean
        console.log('notifications', value);
        setNotifications(value);
    };
    /*  const handleActiveChange = () => {
        if (active === 1) {
            setActive(0);
        } else {
            setActive(1);
        }
    }; */

    const setWeekDayActive = (value) => {
        setWeekDay(value);
    };

    const handleNotificationTypeChange = (value) => {
        setNotificationType(value);
    };

    // Handle time change
    const onChangeTimeOfDay = (newTime) => {
        setTimeMoment(newTime);
        /* 
          const formattedTime = newTime ? newTime.format('HH:mm:ss') : '';
          // Send the updated time back to the server to update the database axios
            .post('/api/update-time', { time: formattedTime })
            .then(() => console.log('Time updated successfully!'))
            .catch((error) => console.error('Failed to update time', error));
        */
    };

    // Function to toggle checkboxes with toast feedback
    const toggleSelectAll = async (isSelected) => {
        const toastId_id = isSelected.toString();
        const toastId = showToast('One moment...', toastId_id, {
            toastId: 'toastIdCreateEvent',
            type: 'success',
            isLoading: true,
        });
        // Track promises to check when all selections are done
        const promises = participants.map(
            (participant) =>
                new Promise((resolve) => {
                    handleAllowedChange(
                        event_id,
                        participant.participant_id,
                        isSelected,
                        null
                    );
                    resolve();
                })
        );

        // Wait until all changes are applied
        await Promise.all(promises);

        // Update the state
        setAllChecked(isSelected);

        // Show a completion toast
        updateToast(toastId, toastId_id, {
            render: 'All done',
            autoClose: 2000,
            type: 'success',

            isLoading: false,
        });
    };

    const tooltipSchedule =
        'Not all events can be once-in-a-lifetime-events, like Woodstock -- ' +
        'August 15th, 1969. Most events are more mundane and repetitive ' +
        'and need to be scheduled for a very specific time every week. ' +
        '... unless you already have the next Freddy Mercury in your lineup?';
    const tooltipEventName =
        'Choosing a good event name is important. Woodstock probably ' +
        "wouldn't have happened if they had named it 'Band Practice 1969'! " +
        'The other two fields are less important (most of the time), but if ' +
        "you provide those details then 'Short description' will appear " +
        'alongside the event name when paricipants view their events list ' +
        "page and 'Message' will appear at the top when they click to see " +
        'the event details.';
    const tooltipGroupAndVenue =
        'If all you need to organize is your garage band practice then ' +
        "you won't need more than one group and one venue (tip: use your " +
        'Band Name and the address of the garage you are renting from your ' +
        'second cousin third removed). However, when your other friends ask ' +
        "you to start organizing Sunday Football as well -- that's when " +
        'having additional groups and venues comes in handy. Protip: You ' +
        'can at any time provide additional info, such as a maps link to ' +
        'help participants find the venue. Just click the Edit button to the ' +
        'right of the group/venue name.';
    const tooltipParticipantCount =
        "You'll probably want to set the min and max for an event -- because " +
        "it's awkward having three people show up for a blind date (let " +
        'alone only one!). The event registration page will prominently show ' +
        'when the minimum has been reached and prevent further registrations ' +
        'once it reaches the max specified.';
    const tooltipAllowedParticipants =
        "Below is a list of all Participants that you've registered and " +
        'a checkbox signifying which ones have access to this event. ' +
        'Protip: To temporarily remove someone from all events, it is easier ' +
        "to change 'Active'/'Inactive' status for that Participant rather " +
        'than remove them from all events individually.';
    const tooltipNotifications =
        'Email notifications drastically increase the response rate for your ' +
        'events, because they provide participants with timely reminders ' +
        'as well as a super easy way for everyone to respond (with just a ' +
        "click of a button in an email). It's optional, though, and you can " +
        'always edit the event later to add email notifications. Your ' +
        'participants can also turn them off.';

    const step1 = (
        <>
            <div className="schedule">
                <div className="title">
                    Schedule{' '}
                    <Tooltip title={tooltipSchedule}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
                <div className="field">
                    <input
                        className="is-checkradio"
                        id="eventSingle"
                        type="radio"
                        value={true}
                        checked={is_single_event}
                        onChange={handleSingleEventChange}
                    />
                    <label htmlFor="eventSingle">Single</label>
                    <input
                        className="is-checkradio"
                        id="eventRepeating"
                        type="radio"
                        value={false}
                        checked={!is_single_event}
                        onChange={handleSingleEventChange}
                    />
                    <label htmlFor="eventRepeating">Repeating</label>
                </div>
                <div className="columns">
                    <div className="column">
                        <>
                            {!is_single_event && (
                                <div className="select">
                                    <Space wrap>
                                        <Select
                                            style={{ width: 120 }}
                                            id="day"
                                            popupClassName="repeatDayClass"
                                            size={'large'}
                                            value={weekday}
                                            onChange={setWeekDayActive}
                                            options={[
                                                {
                                                    id: 'dayValue0',
                                                    value: 0,
                                                    label: 'Sunday',
                                                },
                                                {
                                                    id: 'dayValue1',
                                                    value: 1,
                                                    label: 'Monday',
                                                },
                                                {
                                                    id: 'dayValue2',
                                                    value: 2,
                                                    label: 'Tuesday',
                                                },
                                                {
                                                    id: 'dayValue3',
                                                    value: 3,
                                                    label: 'Wednesday',
                                                },
                                                {
                                                    id: 'dayValue4',
                                                    value: 4,
                                                    label: 'Thursday',
                                                },
                                                {
                                                    id: 'dayValue5',
                                                    value: 5,
                                                    label: 'Friday',
                                                },
                                                {
                                                    id: 'dayValue6',
                                                    value: 6,
                                                    label: 'Saturday',
                                                },
                                            ]}
                                        />
                                    </Space>{' '}
                                    <div className="select">
                                        <div>
                                            <TimePicker
                                                id="time"
                                                popupClassName="timeClass"
                                                type="time"
                                                size={'large'}
                                                onChange={onChangeTimeOfDay}
                                                needConfirm={needConfirm}
                                                format={timeFormat}
                                                value={time_moment}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        <>
                            {is_single_event && (
                                <div>
                                    <DatePicker
                                        id="singleDayTime"
                                        popupClassName="singleDayTimeClass"
                                        disabledDate={
                                            utils.disableDateYesterday
                                        }
                                        showTime
                                        format={'YYYY/MM/DD HH:mm'}
                                        size={'large'}
                                        needConfirm={needConfirm}
                                        onChange={(value) => {
                                            setDayMoment(value);
                                        }}
                                        value={day_moment}
                                    />
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <hr />
            <div className="title">
                Name and description{' '}
                <Tooltip title={tooltipEventName}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label className="label">Event name</label>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                id="name"
                                placeholder="Enter event name"
                                value={name}
                                onChange={(e) => setEventName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Short description</label>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                id="short_description"
                                value={short_description}
                                onChange={(e) =>
                                    setShortDescription(e.target.value)
                                }
                                placeholder="Enter event description (short)"
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">Message</label>
                        <div className="control">
                            <textarea
                                className="textarea"
                                type="text"
                                id="long_description"
                                value={long_description || ''}
                                placeholder="Enter message (optional)"
                                onChange={(e) =>
                                    setLongDescription(e.target.value)
                                }
                            ></textarea>
                        </div>
                        <small>
                            Appears at the top of the event page (optional).
                        </small>
                    </div>
                </div>
            </div>

            {/* <h2>Cancelled</h2>
                            <div className="field">
                                <input
                                    className="is-checkradio"
                                    id="exampleRadioInline3"
                                    type="radio"
                                    name="exampleRadioInline3"
                                />
                                <label htmlFor="exampleRadioInline3">Yes</label>
                                <input
                                    className="is-checkradio"
                                    id="exampleRadioInline4"
                                    type="radio"
                                    name="exampleRadioInline3"
                                />
                                <label htmlFor="exampleRadioInline4">No</label>
                            </div>
                            <small>
                                If an event is cancelled, it will still remain
                                published. The response page will show a
                                'Cancelled' message and not accept any updates.
                                If required, add more detail in the 'Message'
                                field.
                            </small> */}
        </>
    );

    const step2 = (
        <>
            <div className="title">
                Groups and venue{' '}
                <Tooltip title={tooltipGroupAndVenue}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div className="columns">
                <div className="column is-full">
                    <div className="field">
                        <Gathering
                            gathering_id={gathering_id}
                            setGatheringId={setGatheringId}
                        ></Gathering>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-full">
                    <div className="field">
                        <Venue
                            venue_id={venue_id}
                            setVenueId={setVenueId}
                        ></Venue>
                    </div>
                </div>
            </div>
            <hr />
            <div className="title">
                Participant count{' '}
                <Tooltip title={tooltipParticipantCount}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div className="columns">
                <div className="column is-narrow">
                    <EventSettings
                        min_participants={min_participants}
                        max_participants={max_participants}
                        setMinParticipants={setMinParticipants}
                        setMaxParticipants={setMaxParticipants}
                    />
                </div>
            </div>
        </>
    );

    const step3 = (
        <>
            <div className="columns">
                <div className="column">
                    <h2>
                        Allowed participants{' '}
                        <Tooltip title={tooltipAllowedParticipants}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </h2>
                    <div className="field">
                        <div className="select-all-none">
                            <div
                                className="btn-with-icon checked"
                                onClick={() => toggleSelectAll(true)}
                            >
                                Select all
                            </div>

                            <div
                                className="btn-with-icon unchecked"
                                onClick={() => toggleSelectAll(false)}
                            >
                                Select none
                            </div>
                        </div>{' '}
                        *
                        <div className="participant-list">
                            {participants && (
                                <div>
                                    {participants.map((participant) => (
                                        <div
                                            className="participant"
                                            key={participant.participant_id}
                                        >
                                            <div className="field">
                                                <input
                                                    className="is-checkradio is-small"
                                                    id={`checkbox-${participant.participant_id}`} // Unique ID
                                                    type="checkbox"
                                                    name={`checkbox-${participant.participant_id}`} // Unique name, if needed
                                                    value="true"
                                                    checked={
                                                        allChecked ||
                                                        participant.hasAllowedParticipant
                                                    }
                                                    onChange={(e) =>
                                                        handleAllowedChange(
                                                            event_id,
                                                            participant.participant_id,
                                                            e.target.checked,
                                                            e
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor={`checkbox-${participant.participant_id}`}
                                                >
                                                    {participant.name}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </>
    );

    const step4 = (
        <>
            <div className="columns">
                <div className="column">
                    <div className="title">
                        Notification (email){' '}
                        <Tooltip title={tooltipNotifications}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <div className="field">
                        <input
                            className="is-checkradio"
                            id="notificationsOn"
                            type="radio"
                            value={true}
                            checked={notifications === true}
                            onChange={handleNotificationsChange}
                        />
                        <label htmlFor="notificationsOn">Yes</label>
                        <input
                            className="is-checkradio"
                            id="notificationsOff"
                            type="radio"
                            value={false}
                            checked={notifications === false}
                            onChange={handleNotificationsChange}
                        />
                        <label htmlFor="notificationsOff">No</label>
                    </div>
                    {notifications && (
                        <>
                            <div className="select">
                                <Space wrap>
                                    <Select
                                        style={{ width: 160 }}
                                        id="day"
                                        popupClassName="repeatDayClass"
                                        size={'large'}
                                        value={notificationType}
                                        onChange={handleNotificationTypeChange}
                                        options={[
                                            {
                                                id: 'dayValue0',
                                                value: 0,
                                                label: 'Same day',
                                            },
                                            {
                                                id: 'dayValue1',
                                                value: 1,
                                                label: '1 day before',
                                            },
                                            {
                                                id: 'dayValue2',
                                                value: 2,
                                                label: '2 days before',
                                            },
                                        ]}
                                    />
                                </Space>
                            </div>
                            <br />
                            <div style={{ marginTop: 8 }}>
                                <small>
                                    An email will be sent to each person invited
                                    to take part in the event.{' '}
                                    <b>Preview the email</b>
                                </small>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <hr />
            <div className="columns disabled">
                <div className="column">
                    <h2>Other stuff (not available)</h2>
                    <div className="field">
                        <label className="label">Event password</label>
                        <div className="control">
                            <input
                                size="6"
                                style={{ width: '100px' }}
                                maxLength="10"
                                className="input"
                                type="text"
                                placeholder="Password"
                            />
                        </div>
                        <small>
                            Specify a password to view the Event URL (optional).
                        </small>
                    </div>
                </div>
            </div>
        </>
    );

    const steps = [
        {
            title: 'Name and schedule',
            content: step1,
        },
        {
            title: 'Groups and venue',
            content: step2,
        },
        {
            title: 'Notifications',
            content: step4,
        },
    ];

    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <>
            {creatingEvent && (
                <>
                    <h1 id="createEventPage">Create Event</h1>
                    <br />

                    <Steps current={current} items={items} />
                    <br />
                    <div className="block">{steps[current].content}</div>
                    <div
                        style={{
                            marginTop: 24,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {current > 0 && (
                            <button
                                id="buttonPrevious"
                                className="button is-text"
                                style={{ marginLeft: 10 }}
                                onClick={() => prev()}
                            >
                                Previous
                            </button>
                        )}
                        {current < steps.length - 1 && (
                            <button
                                id="buttonNext"
                                className="button"
                                onClick={() => next()}
                            >
                                Next
                            </button>
                        )}
                        {current === steps.length - 1 && (
                            <>
                                {eventData && (
                                    <input
                                        type="hidden"
                                        id="event_id"
                                        value={eventData.id}
                                    />
                                )}

                                {creatingEvent && (
                                    <button
                                        id="buttonCreateEvent"
                                        className="button is-primary"
                                        onClick={startCreateEvent}
                                    >
                                        Create
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}

            {!creatingEvent && (
                <div className="block">
                    <h1 id="editEventPage">Edit Event Template: {name}</h1>
                    <br />
                    {step1}
                    <hr />
                    {step2}
                    <hr />
                    {step3}
                    <hr />
                    {step4}
                </div>
            )}
            {!creatingEvent && (
                <button
                    id="buttonUpdateEvent"
                    className="button is-primary"
                    onClick={startUpdateEvent}
                >
                    Update
                </button>
            )}
            {!creatingEvent && (
                <button
                    id="buttonDeleteEvent"
                    className="button is-danger"
                    style={{ marginLeft: 10 }}
                    onClick={handleDeleteConfirmation}
                >
                    Delete
                </button>
            )}

            <br />
            {/*  
                       Disabled as if active is not 1, the event is not shown in the list
                       <div className="activeSwitch">
                            <div className="field">
                                <input
                                    id="active"
                                    type="checkbox"
                                    name="active"
                                    checked={active}
                                    onClick={handleActiveChange}
                                    className="switch is-rounded is-success"
                                    value={1}
                                />
                                <label htmlFor="active">Active</label>
                            </div>
                        </div> */}

            {/* Delete confirmation modal */}
            {showDeleteConfirmation && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Delete event</p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={handleDeleteConfirmation}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Are you sure? This can not be undone!
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="buttonConfirmDeleteEvent"
                                className="button is-danger"
                                onClick={startDeleteEvent}
                            >
                                Confirm delete
                            </button>
                            <button
                                className="button"
                                onClick={handleDeleteConfirmation}
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
        </>
    );
}

export default ManageEvent;
